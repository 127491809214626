@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

html,body {
	font-family: 'Poppins', sans-serif;
	scroll-behavior: smooth !important;
	background: white;
	width: 100vw;
	overflow-x: hidden;
}

*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

a {
	@apply text-xl font-medium text-primary  transition-all duration-200 ease-in;
}

/* Play Button Styles */
.drawn {
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	animation: draw 3s ease-in infinite alternate; /* Use "alternate" to reverse the animation */
}
.path-animation {
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	animation: draw 2s ease forwards;
}
@keyframes draw {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
/* Play Button Styles End */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 999999;
	display: flex;
	justify-content: center;
	align-items: center;
}
.video-container {
	position: relative;
}

.btn-shadow {
	transition: all 300ms ease-out;
}

.btn-shadow:hover {
	box-shadow: 0px 0px 0px 2px rgb(132 204 22);
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #b0d238;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

@layer base {

.drawn {
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	animation: draw 3s ease-in infinite alternate; /* Use "alternate" to reverse the animation */
}

@keyframes draw {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
}